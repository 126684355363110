import { decorate, observable, action } from 'mobx';
import moment from 'moment/moment';

import notificationStore from './NotificationStore';
import Api from '../api';

class ReportStore {
  constructor() {
    this.initialData = {
      allTeamsReportData: {
        completionRate: 0,
        coachInteractions: 0,
        tableData: [],
        isLoading: false,
        isTableLoading: false,
        totalTeams: 0,
        totalAssignmentPaths: 0,
        isExcelLoading: false,
      },
      indivdualTeamReportData: {
        isMounted: false,
        teamName: '',
        pathName: '',
        completionRate: 0,
        coachInteractions: 0,
        tableData: [],
        isLoading: false,
        isTableLoading: false,
        totalMembers: 0,
        isExcelLoading: false,
      },
      traineeReportData: {
        teamName: '',
        pathName: '',
        traineeBasicInfo: {},
        tagsData: {},
        completionRate: 0,
        isLoading: false,

        momentsTableData: [],
        totalMoments: 0,
        isMomentsTableLoading: false,

        certificationsTableData: [],
        totalCertifications: 0,
        isCertificationsTableLoading: false,
      },
      activtiyReportData: {
        userCount: 0,
        coachCount: 0,
        totalMoments: 0,
        totalCertifications: 0,
        mostActiveMoments: [],
        mostActiveTeams: [],
        dailyMomentsActivity: [],
        dailyCertificationsActivity: [],
        isOverviewDataLoading: false,
        isReportDataLoading: false,
      },
      momentReportData: {
        momentsFilterChoices: {},
        isMomentFilterChoicesLoading: false,
        momentOverviewData: {},
        isMomentOverviewDataLoading: false,
        momentStatsData: {
          progressInfo: [],
          targetDuration: '-',
        },
        isMomentStatsLoading: false,
        totalTeamMembers: 0,
        teamMemberList: [],
        isMemberListingLoading: false,
      },
      coachesReportData: {
        coachCount: 0,
        coachAndAdminCount: 0,
        coachInteractions: 0,
        coaches: [],
        teams: [],
        isOverviewDataLoading: false,
        isTableLoading: false,
      },
      individualCoachReportData: {
        coach: {},
        coachInteractions: 0,
        starRatingDistribution: [
          { oneStar: 0 },
          { twoStar: 0 },
          { threeStar: 0 },
          { fourStar: 0 },
        ],
        moments: [],
        hasMoreMoments: 0,
        isOverviewDataLoading: false,
        isCoachMomentsLoading: false,
        isMoreMomentsLoading: false,
      },
      assignmentReportData: {
        isAssignmentListLoading: false,
        assignments: [],
        teams: [],
        isAssignmentDataLoading: true,
        assignmentDetails: [],
      },
      aiTrainingReportData: {
        totalAIMoments: 0,
        totalMomentsInTraining: 0,
        totalLiveMoments: 0,
        moments: [],
        assignments: [],
        // teams: [{name: 'All Teams', id: 'all-teams'}],
        isOverviewDataLoading: false,
        isTableLoading: false,
      },
      outstandingReportData: {
        isTeamsListLoading: false,
        isReportDataLoading: false,
        teamsData: [],
      },
    };

    this.allTeamsReportData = {
      ...this.initialData.allTeamsReportData,
    };
    this.indivdualTeamReportData = {
      ...this.initialData.indivdualTeamReportData,
    };
    this.traineeReportData = {
      ...this.initialData.traineeReportData,
    };
    this.activtiyReportData = {
      ...this.initialData.activtiyReportData,
    };
    this.momentReportData = {
      ...this.initialData.momentReportData,
    };
    this.coachesReportData = {
      ...this.initialData.coachesReportData,
    };
    this.individualCoachReportData = {
      ...this.initialData.individualCoachReportData,
    };
    this.assignmentReportData = {
      ...this.initialData.assignmentReportData,
    };
    this.aiTrainingReportData = {
      ...this.initialData.aiTrainingReportData,
    };
    this.outstandingReportData = {
      ...this.initialData.outstandingReportData,
    };
  }

  async getAllTeamsReportsOverviewData(teamId, assignmentPath) {
    this.clearIndivdualTeamReportData();
    this.allTeamsReportData.isLoading = true;
    const { data } = await Api.getAllTeamsReportsOverviewData(
      teamId, assignmentPath);
    this.allTeamsReportData = {
      ...this.allTeamsReportData,
      ...data,
      isLoading: false,
    };
  }

  async getAllTeamsReportsListing(queryParams) {
    this.allTeamsReportData = {
      ...this.allTeamsReportData,
      tableData: [],
      totalTeams: 0,
      totalAssignmentPaths: 0,
      isTableLoading: true,
    };

    const { data } = await Api.getAllTeamsReportsListing(queryParams);

    if (!data) return;

    this.allTeamsReportData = {
      ...this.allTeamsReportData,
      tableData: data.tableData,
      ...(data.totalTeams && { totalTeams: data.totalTeams }),
      ...(data.totalAssignmentPaths && { totalAssignmentPaths: data.totalAssignmentPaths }),
      isTableLoading: false,
    };
  }

  async getIndividualTeamReportOverviewData({
    teamId,
    pathId,
    assignmentPath,
    listingGroup,
  }) {
    if (!this.indivdualTeamReportData.isMounted) {
      if (this.indivdualTeamReportData.tableData.length > 0) {
        return;
      }
    }
    this.indivdualTeamReportData.isLoading = true;
    const { data } = await Api.getIndividualTeamReportOverviewData({
      teamId,
      pathId,
      assignmentPath,
      listingGroup,
    });
    this.indivdualTeamReportData = {
      ...this.indivdualTeamReportData,
      ...data,
      isLoading: false,
    };
  }

  async getIndividualTeamReportListing(teamId, pathId, queryParams) {
    if (!this.indivdualTeamReportData.isMounted) {
      this.indivdualTeamReportData.isMounted = true;
      if (this.indivdualTeamReportData.tableData.length > 0) {
        return;
      }
    }
    this.indivdualTeamReportData = {
      ...this.indivdualTeamReportData,
      tableData: [],
      isTableLoading: true,
    };
    const { data } = await Api.getIndividualTeamReportListing(
      teamId, pathId, queryParams);
    this.indivdualTeamReportData = {
      ...this.indivdualTeamReportData,
      ...data,
      isTableLoading: false,
    };
  }

  getTraineeTeamReportOverviewData = async (teamId, traineeId, assignmentPath, pathId) => {
    this.traineeReportData.isLoading = true;
    const { data } = await Api.getTraineeTeamReportOverviewData(
      teamId,
      traineeId,
      assignmentPath,
      pathId,
    );

    if (!data) {
      window.location.href = '/';

      return;
    }

    this.traineeReportData = {
      ...this.traineeReportData,
      ...data,
      momentsTableData: [],
      certificationsTableData: [],
      isLoading: false,
    };
    return this.traineeReportData;
  }

  async getTraineeTeamReportMomentsAndCertificationsCounts(
    teamId,
    traineeId,
    queryParams) {
    this.traineeReportData.isMomentsTableLoading = true;
    this.traineeReportData.isCertificationsTableLoading = true;

    const { data } = await Api.getTraineeTeamReportMomentsAndCertificationsCounts(
      teamId,
      traineeId,
      queryParams,
    );
    this.traineeReportData = {
      ...this.traineeReportData,
      ...data,
      isMomentsTableLoading: false,
      isCertificationsTableLoading: false,
    };
  }

  async getTraineeTeamReportMomentsListing(teamId, traineeId, queryParams) {
    this.traineeReportData.isMomentsTableLoading = true;
    const { data } = await Api.getTraineeTeamReportMomentsListing(teamId, traineeId, queryParams);
    this.traineeReportData = {
      ...this.traineeReportData,
      ...data,
      isMomentsTableLoading: false,
    };
  }

  async getTraineeTeamReportCertificationListing(teamId, traineeId, queryParams) {
    this.traineeReportData.isCertificationsTableLoading = true;
    const { data } = await Api.getTraineeTeamReportCertificationListing(
      teamId, traineeId, queryParams);
    this.traineeReportData = {
      ...this.traineeReportData,
      ...data,
      isCertificationsTableLoading: false,
    };
  }

  getActivityReportOverviewData = async () => {
    this.activtiyReportData.isOverviewDataLoading = true;
    const { data } = await Api.getActivityReportOverviewData();

    this.activtiyReportData = {
      ...this.activtiyReportData,
      ...data,
      isOverviewDataLoading: false,
    };
  }

  getActivityReportData = async (filterValues) => {
    this.activtiyReportData.isReportDataLoading = true;
    const { data } = await Api.getActivityReportData(filterValues);

    this.activtiyReportData = {
      ...this.activtiyReportData,
      ...data,
      isReportDataLoading: false,
    };
  }

  async getMomentReportMomentChoices() {
    this.momentReportData.isMomentFilterChoicesLoading = true;
    const { data } = await Api.getMomentReportMomentChoices();

    this.momentReportData = {
      ...this.momentReportData,
      ...data,
      isMomentFilterChoicesLoading: false,
    };
  }

  async getMomentReportOverviewData(momentId) {
    this.momentReportData.isMomentOverviewDataLoading = true;
    const { data } = await Api.getMomentReportOverviewData(momentId);

    this.momentReportData = {
      ...this.momentReportData,
      ...data,
      isMomentOverviewDataLoading: false,
    };
  }

  async getMomentReportStatsData(momentId, queryParams) {
    this.momentReportData.isMomentStatsLoading = true;
    const updatedQueryParams = { ...queryParams };

    if (updatedQueryParams && updatedQueryParams.teamsId && updatedQueryParams.teamsId.find((item) => item === -1)) {
      updatedQueryParams.teamsId = null;
    }

    const { data } = await Api.getMomentReportStatsData(momentId, updatedQueryParams);

    this.momentReportData = {
      ...this.momentReportData,
      ...data,
      isMomentStatsLoading: false,
    };
  }

  async getMomentReportMemberListingData(momentId, queryParams) {
    this.momentReportData.isMemberListingLoading = true;
    const updatedQueryParams = { ...queryParams };

    if (updatedQueryParams && updatedQueryParams.teamsId && updatedQueryParams.teamsId.find((item) => item === -1)) {
      updatedQueryParams.teamsId = null;
    }

    const { data } = await Api.getMomentReportMemberListingData(momentId, updatedQueryParams);

    this.momentReportData = {
      ...this.momentReportData,
      ...data,
      isMemberListingLoading: false,
    };
  }

  async getTeamsForCoachReport() {
    const { data: teams } = await Api.getAllTeams() || {};

    teams.unshift({ name: 'All Teams', id: -1, tierId: 4 });

    this.coachesReportData = {
      ...this.coachesReportData,
      teams,
    };

    return teams;
  }

  async getCoachesReportOverviewData(queryParams) {
    this.coachesReportData.isOverviewDataLoading = true;

    const teams = this.coachesReportData.teams?.length > 0
      ? this.coachesReportData.teams
      : (await this.getTeamsForCoachReport());

    const { data } = await Api.getCoachesReportOverviewData(queryParams);

    this.coachesReportData = {
      ...this.coachesReportData,
      ...data,
      teams,
      isOverviewDataLoading: false,
    };
  }

  async getAllCoachesListing(queryParams, teamId, abortController = null) {
    this.coachesReportData.isTableLoading = true;

    const { data } = await Api.getAllCoachesListing(queryParams, teamId, abortController);

    if (!data) return;

    this.coachesReportData = {
      ...this.coachesReportData,
      ...data,
      isTableLoading: false,
    };
  }

  async getCoachReportOverviewData(coachId, filterValues) {
    this.individualCoachReportData.isOverviewDataLoading = true;
    const { data } = await Api.getCoachReportOverviewData(coachId, filterValues);

    this.individualCoachReportData = {
      ...this.individualCoachReportData,
      ...data,
      isOverviewDataLoading: false,
    };
  }

  async getIndividualCoachData(queryParams, coachId, isMoreMomentsLoading, filterValues, loadMoreButtonClicked) {
    if (!isMoreMomentsLoading) {
      this.individualCoachReportData.isCoachMomentsLoading = true;
    } else {
      this.individualCoachReportData.isMoreMomentsLoading = true;
    }

    const { data } = await Api.getIndividualCoachData(queryParams, coachId, filterValues);
    let moments = [];
    if (!loadMoreButtonClicked) {
      moments = data.moments;
    } else {
      moments = [...this.individualCoachReportData.moments, ...data.moments];
    }

    this.individualCoachReportData = {
      ...this.individualCoachReportData,
      ...data,
      moments,
      isCoachMomentsLoading: false,
      isMoreMomentsLoading: false,
    };
  }

  async getAssignmentListing() {
    this.assignmentReportData.isAssignmentListLoading = true;

    const { data } = await Api.getAssignmentList();

    if (!data) return;

    data.teams.unshift({ name: 'All Teams', id: -1, tierId: 4 });

    this.assignmentReportData = {
      ...this.assignmentReportData,
      ...data,
      isAssignmentListLoading: false,
    };
  }

  async getAssignmentByIdData(
    assignmentId,
    teamId,
    startDate,
    endDate,
    assignmentPath,
  ) {
    this.assignmentReportData.isAssignmentDataLoading = true;

    const { data } = await Api.getAssignmentByIdData(
      assignmentId,
      teamId,
      startDate,
      endDate,
      assignmentPath,
    );

    this.assignmentReportData = {
      ...this.assignmentReportData,
      ...data,
      isAssignmentDataLoading: false,
    };
  }

  async getAITrainingOverviewData(queryParams) {
    this.aiTrainingReportData.isOverviewDataLoading = true;
    const { data } = await Api.getAITrainingOverviewData(queryParams);

    if (!data) return;

    data.assignments.unshift({ name: 'All Paths', id: -1 });

    this.aiTrainingReportData = {
      ...this.aiTrainingReportData,
      ...data,
      isOverviewDataLoading: false,
    };
  }

  async getAITrainingListingData(queryParams) {
    this.aiTrainingReportData.isTableLoading = true;
    const { data } = await Api.getAITrainingListingData(queryParams);

    this.aiTrainingReportData = {
      ...this.aiTrainingReportData,
      moments: data?.moments || [],
      isTableLoading: false,
    };
  }

  getTeamProgressSheetReport = async (teamId, assignmentPath) => {
    this.allTeamsReportData.isExcelLoading = true;
    this.indivdualTeamReportData.isExcelLoading = true;

    const { data: { key, finished } } = await Api
      .getTeamProgressExcel(teamId, assignmentPath, true);

    if (key) {
      const poll = async () => {
        const { data } = await Api.getTeamProgressExcel(teamId, assignmentPath, false, key);

        if (data.url) {
          const pathTypes = {
            core: 'Core',
            explore: 'Explore',
          };

          const link = document.createElement('a');
          link.href = data.url;
          link.target = '_blank';
          link.setAttribute('download', `${moment(new Date()).format('DD/MM/YYYY')} Team ${teamId || 'Tier 1'} Progress Report - ${pathTypes[assignmentPath] || ''}.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.allTeamsReportData.isExcelLoading = false;
          this.indivdualTeamReportData.isExcelLoading = false;

          return;
        }

        if (!data.finished) {
          setTimeout(poll, 4000);
        }
      };

      if (!finished) {
        setTimeout(poll, 4000);
      }
    } else {
      this.allTeamsReportData.isExcelLoading = false;
      this.indivdualTeamReportData.isExcelLoading = false;

      notificationStore.createNotification('Unable to download report, please try again later.', 'error');
    }
  }

  async updateMomentStatus({ momentId }) {
    const moment = this.aiTrainingReportData.moments.find(({ id }) => id === momentId);

    if (moment && (moment.aiPreferences.isLive === true || moment.aiPreferences.isLive === false)) {
      let isLive = false;
      const momentData = {
        aiPreferences: {
          ...moment.aiPreferences,
          isLive: !moment.aiPreferences.isLive,
        },
        id: momentId,
      };

      if (momentData.aiPreferences.isLive) {
        isLive = true;
      }

      const { data: { aiPreferences } } = await Api.updateMoment({ momentId, momentData });
      this.aiTrainingReportData.moments = this.aiTrainingReportData.moments
        .map((momentObj) => (momentObj.id === momentId
          ? { ...momentObj, aiPreferences }
          : momentObj));

      if (isLive) {
        this.aiTrainingReportData.totalMomentsInTraining = this.aiTrainingReportData.totalMomentsInTraining - 1;
        this.aiTrainingReportData.totalLiveMoments = this.aiTrainingReportData.totalLiveMoments + 1;
      } else {
        this.aiTrainingReportData.totalMomentsInTraining = this.aiTrainingReportData.totalMomentsInTraining + 1;
        this.aiTrainingReportData.totalLiveMoments = this.aiTrainingReportData.totalLiveMoments - 1;
      }
    }
  }

  async getOutsandingReportData(teamId, assignmentPath) {
    this.outstandingReportData.isTableLoading = true;
    const { data } = await Api.getOutsandingReportData(teamId, assignmentPath);

    if (!data) return null;

    this.outstandingReportData = {
      ...this.outstandingReportData,
      teamsData: [...data],
      isTableLoading: false,
    };

    return data;
  }

  clearAllTeamsReportData() {
    this.allTeamsReportData = {
      ...this.initialData.allTeamsReportData,
    };
  }

  clearIndivdualTeamReportData() {
    this.indivdualTeamReportData = {
      ...this.initialData.indivdualTeamReportData,
    };
  }

  unMountIndividualTeamReport() {
    this.indivdualTeamReportData.isMounted = false;
  }

  clearTraineeReportData() {
    this.traineeReportData = {
      ...this.initialData.traineeReportData,
    };
  }

  clearActivityReportData = () => {
    this.activtiyReportData = {
      ...this.initialData.activtiyReportData,
    };
  }

  clearMomentReportData() {
    this.momentReportData = {
      ...this.initialData.momentReportData,
    };
  }

  clearAllCoachesReportData() {
    this.coachesReportData = {
      ...this.initialData.coachesReportData,
    };
  }

  clearIndividualCoachReportData() {
    this.individualCoachReportData = {
      ...this.initialData.individualCoachReportData,
    };
  }

  clearAssignmentReportData() {
    this.assignmentReportData = {
      ...this.initialData.assignmentReportData,
    };
  }

  clearOustandingReportData() {
    this.outstandingReportData = {
      ...this.initialData.outstandingReportData,
    };
  }

  clearAITrainingReportData() {
    this.aiTrainingReportData = {
      ...this.initialData.aiTrainingReportData,
    };
  }

  getSkillsReport = async ({
    conversationId, userIds,
  }) => {
    try {
      const { data: skillsReport }
      = await Api.getSkillsReport({
        conversationId, userIds,
      });
      return skillsReport;
    } catch (err) {
      throw new Error('Error in get skills report', err);
    }
  };
}

decorate(ReportStore, {
  allTeamsReportData: observable,
  indivdualTeamReportData: observable,
  traineeReportData: observable,
  activtiyReportData: observable,
  momentReportData: observable,
  coachesReportData: observable,
  individualCoachReportData: observable,
  assignmentReportData: observable,
  aiTrainingReportData: observable,
  outstandingReportData: observable,

  getAllTeamsReportsOverviewData: action,
  getAllTeamsReportsListing: action,
  clearAllTeamsReportData: action,
  updateMomentStatus: action,

  getIndividualTeamReportOverviewData: action,
  getIndividualTeamReportListing: action,
  clearIndivdualTeamReportData: action,
  unMountIndividualTeamReport: action,

  getTraineeTeamReportOverviewData: action,
  getTraineeTeamReportMomentsAndCertificationsCounts: action,
  getTraineeTeamReportMomentsListing: action,
  getTraineeTeamReportCertificationListing: action,
  clearTraineeReportData: action,

  getActivityReportOverviewData: action,
  getActivityReportData: action,
  clearActivityReportData: action,

  getAssignmentListing: action,
  getAssignmentByIdData: action,

  getMomentReportMomentChoices: action,
  getMomentReportOverviewData: action,
  getMomentReportStatsData: action,
  getMomentReportMemberListingData: action,
  clearMomentReportData: action,

  getCoachesReportOverviewData: action,
  getAllCoachesListing: action,
  clearAllCoachesReportData: action,

  getCoachReportOverviewData: action,
  getIndividualCoachData: action,
  clearIndividualCoachReportData: action,

  getAITrainingOverviewData: action,
  getAITrainingListingData: action,

  getOutsandingReportData: action,
  clearOustandingReportData: action,
});

export default new ReportStore();
