/* eslint-disable no-loop-func */
/* eslint-disable prefer-destructuring */
import { decorate, observable, action } from 'mobx';

import {
  calculateAssignmentStatus,
  calculateMomentProgress,
  calculateSimulationProgress,
} from '@frontend/utils/assignmentUtilities';

import UserStore from './UserStore';
import SimulationStore from './SimulationStore';

import Api from '../api';

const progressInitialState = {
  passed: 0,
  notStarted: 0,
  inProgress: 0,
};

class AssignmentStore {
  constructor() {
    this.corePath = {};
    this.assignment = [];
    this.explorePath = [];
    this.userBadges = [];
    this.isAssignmentLoading = true;
    this.isPathLoading = true;
    this.isNextItemLoading = true;
    this.nextUpAssignmentItem = {};

    this.exploreAssignments = [];

    this.assignmentItemsInRetakeStatus = [];
    this.assignmentItemsInProgressStatus = [];
    this.assignmentItemsInNotStartedStatus = [];
    this.assignmentItemsInCompletedStatus = [];
    this.allSimulationsWithMomentsInfo = [];
    this.allAssignmentsInOrder = [];
    this.allMomentsAndAIConversations = progressInitialState;
    this.exploreSequencesProgress = progressInitialState;

    this.exploreAssignmentsStatus = {};
    this.corePathStats = {
      totalInProgress: 0,
      totalCompleted: 0,
      totalAssignments: 0,
    };
    this.explorePathStats = {
      totalInProgress: 0,
      totalCompleted: 0,
      totalAssignments: 0,
    };
  }

  async fetchAssignment() {
    this.isAssignmentLoading = true;

    const { data: { corePath, explorePath } } = await Api.getUserAssignment() || {};

    this.corePath = corePath?.[0];
    this.assignment = corePath?.[0]?.sequences;
    this.explorePath = explorePath;

    if (this.exploreAssignments?.length) {
      this.exploreAssignments.forEach((exploreAssignment) => {
        exploreAssignment.isAdded = false;

        const foundAssignment = this.explorePath.find(({ id }) => id === exploreAssignment.id);

        if (foundAssignment) exploreAssignment.isAdded = true;
      });
    }

    this.isAssignmentLoading = false;
  }

  async fetchExploreAssignments(teamIds = [], searchText = '') {
    this.isAssignmentLoading = true;

    const { data: exploreAssignments } = await Api.getExploreAssignments(teamIds, searchText) || {};

    this.exploreAssignments = exploreAssignments;

    this.isAssignmentLoading = false;

    this.exploreAssignments?.forEach((exploreAssignment) => {
      const foundAssignment = this.explorePath.find(({ id }) => id === exploreAssignment.id);

      if (foundAssignment) exploreAssignment.isAdded = true;
    });

    return exploreAssignments;
  }

  clearAssignmentProgress() {
    this.isPathLoading = true;

    this.assignmentItemsInNotStartedStatus = [];
    this.assignmentItemsInProgressStatus = [];
    this.assignmentItemsInCompletedStatus = [];
    this.assignmentItemsInRetakeStatus = [];
    this.allSimulationsWithMomentsInfo = [];
    this.allAssignmentsInOrder = [];
    this.allMomentsAndAIConversations = progressInitialState;

    this.exploreAssignmentsStatus = {};
    this.exploreSequencesProgress = progressInitialState;
  }

  resetAssignment() {
    this.isAssignmentLoading = true;
    this.isNextItemLoading = true;
    this.assignment = [];

    this.clearAssignmentProgress();
  }

  getExploreProgress({
    selectedUserActivity = [],
    selectedUserConversationActivity = [],
    explorePath = this.explorePath || [],
  }) {
    const uniqueProgress = {
      passed: new Set(),
      notStarted: new Set(),
      inProgress: new Set(),
    };

    for (let i = 0; i < explorePath.length; i++) {
      const exploreAssignment = explorePath[i];

      const simulations = exploreAssignment.sequences.filter(({ sequenceType }) => sequenceType === 'simulation');
      const moments = exploreAssignment.sequences.filter(({ sequenceType }) => sequenceType === 'moment');

      const simulationsWithStatus = calculateSimulationProgress(
        simulations,
        selectedUserConversationActivity,
        selectedUserActivity,
        uniqueProgress,
        this.assignmentItemsInRetakeStatus,
        this.assignmentItemsInCompletedStatus,
      );

      const momentsWithStatus = calculateMomentProgress(
        moments,
        uniqueProgress,
        selectedUserActivity,
      );

      const exploreAssignmentsStatus = {
        assignmentItemsInNotStartedStatus: [],
        assignmentItemsInRetakeStatus: [],
        assignmentItemsInProgressStatus: [],
        assignmentItemsInCompletedStatus: [],
        allSimulationsWithMomentsInfo: [],
        allAssignmentsInOrder: [],
      };

      calculateAssignmentStatus(
        exploreAssignment.sequences,
        momentsWithStatus,
        simulationsWithStatus,
        exploreAssignmentsStatus.assignmentItemsInNotStartedStatus,
        exploreAssignmentsStatus.assignmentItemsInRetakeStatus,
        exploreAssignmentsStatus.assignmentItemsInProgressStatus,
        exploreAssignmentsStatus.assignmentItemsInCompletedStatus,
        exploreAssignmentsStatus.allSimulationsWithMomentsInfo,
        exploreAssignmentsStatus.allAssignmentsInOrder,
      );

      this.exploreAssignmentsStatus[exploreAssignment.id] = exploreAssignmentsStatus;
    }

    this.exploreSequencesProgress = {
      passed: uniqueProgress.passed.size,
      notStarted: uniqueProgress.notStarted.size,
      inProgress: uniqueProgress.inProgress.size,
    };
  }

  getAssignmentProgress({
    selectedUserActivity = [],
    selectedUserConversationActivity = [],
  }) {
    this.isPathLoading = true;

    this.clearAssignmentProgress();

    if (this.explorePath) {
      this.getExploreProgress({
        selectedUserActivity,
        selectedUserConversationActivity,
        explorePath: this.explorePath,
      });
    }

    const userPathAssignment = this.assignment;

    if (!userPathAssignment?.length) return;

    const simulations = userPathAssignment.filter(({ sequenceType }) => sequenceType === 'simulation');
    const moments = userPathAssignment.filter(({ sequenceType }) => sequenceType === 'moment');

    const uniqueProgress = {
      passed: new Set(),
      notStarted: new Set(),
      inProgress: new Set(),
    };

    const simulationsWithStatus = calculateSimulationProgress(
      simulations,
      selectedUserConversationActivity,
      selectedUserActivity,
      uniqueProgress,
      this.assignmentItemsInRetakeStatus,
      this.assignmentItemsInCompletedStatus,
    );

    const momentsWithStatus = calculateMomentProgress(
      moments,
      uniqueProgress,
      selectedUserActivity,
    );

    calculateAssignmentStatus(
      userPathAssignment,
      momentsWithStatus,
      simulationsWithStatus,
      this.assignmentItemsInNotStartedStatus,
      this.assignmentItemsInRetakeStatus,
      this.assignmentItemsInProgressStatus,
      this.assignmentItemsInCompletedStatus,
      this.allSimulationsWithMomentsInfo,
      this.allAssignmentsInOrder,
    );

    this.assignmentItemsInRetakeStatus = this.assignmentItemsInRetakeStatus
      .reduce(
        (acc, item) => (acc.some(({ id }) => id === item.id) ? acc : [...acc, item]), [],
      ).sort((
        { date_updated: dateUpdatedA },
        { date_updated: dateUpdatedB },
      ) => (dateUpdatedA < dateUpdatedB ? -1 : 1));

    this.allMomentsAndAIConversations = {
      passed: uniqueProgress.passed.size,
      notStarted: uniqueProgress.notStarted.size,
      inProgress: uniqueProgress.inProgress.size,
    };

    this.isPathLoading = false;
  }

  async getAssignmentProgressV2() {
    const { data } = await Api.getAssignmentsProgress();

    if (!data) {
      this.isPathLoading = false;
      return;
    }

    const { core, explore } = data;

    if (core) {
      const {
        conversationsInProgress,
        momentsInProgress,
        conversationsCompleted,
        momentsCompleted,
        totalActivities,
      } = core;

      this.corePathStats = {
        totalInProgress: conversationsInProgress + momentsInProgress,
        totalCompleted: conversationsCompleted + momentsCompleted,
        totalAssignments: totalActivities,
      };
    }

    if (explore) {
      const {
        conversationsInProgress,
        momentsInProgress,
        conversationsCompleted,
        momentsCompleted,
        totalActivities,
      } = explore;

      this.explorePathStats = {
        totalInProgress: conversationsInProgress + momentsInProgress,
        totalCompleted: conversationsCompleted + momentsCompleted,
        totalAssignments: totalActivities,
      };
    }
  }

  async updateExplorePath() {
    this.isAssignmentLoading = true;

    const { data: { explorePath } } = await Api.getUserAssignment() || {};

    if (this.exploreAssignments.length > 0) {
      this.exploreAssignments.forEach((exploreAssignment) => {
        exploreAssignment.isAdded = false;

        const foundAssignment = explorePath.find(({ id }) => id === exploreAssignment.id);

        if (foundAssignment) exploreAssignment.isAdded = true;
      });
    }

    await UserStore.getUserActivity();

    const { selectedUserActivity, selectedUserConversationActivity } = UserStore;

    this.getExploreProgress({
      selectedUserActivity,
      selectedUserConversationActivity,
      explorePath,
    });

    this.explorePath = explorePath;

    this.isAssignmentLoading = false;
  }

  async updateNextUpAssignmentItem() {
    this.resetAssignment();

    await this.fetchAssignment();

    await UserStore.getUserActivity();

    const { selectedUserActivity, selectedUserConversationActivity } = UserStore;

    this.getAssignmentProgress({
      selectedUserActivity,
      selectedUserConversationActivity,
    });

    const explorePathActivities = Object.values(
      this.exploreAssignmentsStatus,
    )?.flatMap(({ allAssignmentsInOrder }) => (
      allAssignmentsInOrder
    )) || [];
    const arrayToConsider = [...this.allAssignmentsInOrder, ...explorePathActivities];

    if (!arrayToConsider?.length) this.nextUpAssignmentItem = {};

    let nextElement = arrayToConsider?.[0] || {};
    let nextMoment = null;

    for (let i = 0; i < arrayToConsider.length && !nextMoment; i++) {
      nextElement = arrayToConsider[i];

      if (nextElement.sequenceType === 'simulation') {
        // search for the simulation which has the correct content areas and moment order
        const correctSimulation = SimulationStore.simulations
          .find(({ id }) => id === nextElement.id);

        // Fixed the id undefined issue by swapping the
        // order of ContentAreas for now as a hotfix
        // Another solution could be to check based on contentArea Name
        let contentAreas = correctSimulation?.contentAreas
          || correctSimulation?.extra?.contentAreas || nextElement?.ContentAreas;

        if (correctSimulation?.contentAreas || correctSimulation?.extra?.contentAreas) {
          contentAreas = contentAreas.map((contentArea) => {
            const foundContentArea = nextElement.ContentAreas
              ?.find(({ id }) => id === contentArea.id);

            if (!foundContentArea) return contentArea;

            return {
              ...contentArea,
              momentsWithStatus: foundContentArea.Moments,
            }; // adds momentsWithStatus to the contentArea from the assignment
          });
        }

        for (let j = 0; j < contentAreas.length && !nextMoment; j++) {
          const contentArea = contentAreas[j];
          const { isAI } = contentArea;

          if (isAI) {
            const userAttempts = selectedUserConversationActivity
              .filter(({ contentAreaId }) => contentAreaId === contentArea.id);

            if (!userAttempts?.length) { // search for not started conversations
              nextMoment = {
                ...contentArea,
                sequenceType: 'ai-conversation',
                status: 'not_started',
              };
            }
          }
          // search for not started regular moments
          let momentFound;

          const momentsOrder = contentArea.extra?.momentOrderMeta || contentArea.momentOrderMeta;

          if (momentsOrder) {
            const momentsMap = new Map();
            contentArea.momentsWithStatus
              ?.forEach((moment) => momentsMap.set(moment.id, moment));

            const filteredAndOrderedMoments = momentsOrder
              .map((id) => momentsMap.get(id));

            momentFound = filteredAndOrderedMoments?.find(({ status }) => (status === 'not_started'));
          } else { // no order provided
            const orderedMoments = contentArea.Moments?.toJS()
              .sort(({ ContentAreaAndMoment: { order: orderA } },
                { ContentAreaAndMoment: { order: orderB } }) => orderA - orderB);

            momentFound = orderedMoments?.find(({ status }) => (status === 'not_started'));
          }

          if (momentFound) {
            nextMoment = momentFound;
          }
        }
      } else if (nextElement.sequenceType === 'moment') {
        const momentFound = nextElement.status === 'not_started' ? nextElement : null;

        if (momentFound) {
          nextMoment = momentFound;
        }
      }
    }

    if (nextMoment?.sequenceType === 'simulation' || nextMoment?.status !== 'not_started') { // if the loop never broke
      this.nextUpAssignmentItem = {};
    } else {
      this.nextUpAssignmentItem = nextMoment.sequenceType === 'ai-conversation'
        ? {
          ...nextMoment,
          type: 'ai-conversation',
        } : {
          ...nextMoment,
          type: `${nextElement.sequenceType}s`,
          id: nextElement.id,
        };
    }

    this.isNextItemLoading = false;
  }

  async getBadgesForUser() {
    const { data: badges } = await Api.getBadgesForUser() || {};

    if (badges) {
      this.userBadges = badges;
    }

    return badges;
  }

  async setBadgeAsViewed(badgeId) {
    const { data } = await Api.setBadgeAsViewed(badgeId);

    return data;
  }
}

decorate(AssignmentStore, {
  assignment: observable,
  isAssignmentLoading: observable,
  isPathLoading: observable,
  assignmentItemsInNotStartedStatus: observable,
  assignmentItemsInProgressStatus: observable,
  assignmentItemsInRetakeStatus: observable,
  assignmentItemsInCompletedStatus: observable,
  allSimulationsWithMomentsInfo: observable,
  allAssignmentsInOrder: observable,
  allMomentsAndAIConversations: observable,
  nextUpAssignmentItem: observable,
  isNextItemLoading: observable,
  explorePath: observable,
  corePath: observable,
  exploreSequencesProgress: observable,
  exploreAssignmentsStatus: observable,
  exploreAssignments: observable,
  updateNextUpAssignmentItem: action,
  fetchAssignment: action,
  resetAssignment: action,
  getBadgesForUser: action,
  setBadgeAsViewed: action,
  getAssignmentProgressV2: action,
  corePathStats: observable,
  explorePathStats: observable,
});

export default new AssignmentStore();
