import {
  decorate, observable, action, computed,
} from 'mobx';
import _ from 'lodash';
import Cookies from 'js-cookie';

import Api from '../api';

import LanguageStore from './LanguageStore';
import OnboardingStore from './OnboardingStore';
import notificationsStore from './NotificationStore';

import { GENERIC_INVALID_CREDENTIALS_MSG } from '@common/utilities/text-constants';

class UserStore {
  user = null;

  customer = null;

  isFetchingUserProfile = true;

  isAuthenticated = false;

  ssoUrl = null;

  contentLoaded = false;

  content = [];

  team = [];

  users = [];

  userAvatars = [];

  bannerMode = Cookies.get('bannerMode') || 'full';

  selectedUserActivity = [];

  selectedUserConversationActivity = [];

  blockedByPayment = false;

  showingHeader = false;

  selectedInstance = Cookies.get('selectedInstance') || null;

  async finishOnboarding() {
    const { role, startDate, region } = OnboardingStore;

    const { data } = await Api.finishOnboarding({ role, startDate, region });
    this.setUserProfile(data);
  }

  setBannerMode(mode) {
    Cookies.set('bannerMode', mode);
    this.bannerMode = mode;
  }

  setUserProfile(user) {
    this.isFetchingUserProfile = false;
    this.isAuthenticated = true;

    this.user = { ...user, customerUrl: user?.login_url };
    this.customer = user?.customer;

    LanguageStore.setLanguage(this.user?.language);
  }

  async logout() {
    const { data: { logoutUrl } } = await Api.logout() || {};

    Cookies.remove('selectedInstance');

    const customerUrls = {
      16: '/trugreen',
      32: '/genpact',
      38: '/ttec',
      50: '/ttec',
    };

    if (logoutUrl) {
      window.location.href = logoutUrl;
      return false;
    }

    if (this.user.customer_id in customerUrls) {
      window.location.href = customerUrls[this.user.customer_id];
    } else if (this.user.customerUrl) {
      window.location.href = this.user.customerUrl;
    } else {
      window.location.href = '/login';
    }
  }

  async login(username) {
    try {
      const { data } = await Api.login(username);

      const { url } = data;
      if (url) {
        window.location.href = url;
        return;
      }

      if (data !== undefined) {
        this.setUserProfile(data);
      }
    } catch (e) {
      // TODO
    }
  }

  async loginRedirect(email) {
    try {
      const response = await Api.getLoginMechanism(email.trim());

      if (response?.status !== 200 || !response.data.next) {
        alert(GENERIC_INVALID_CREDENTIALS_MSG);
      } else {
        this.user = { ...this.user, customerUrl: response.data.next };
        const redirectPath = localStorage.getItem('relayState') || '';

        if (!redirectPath.includes('login')) {
          const loginUrl = new URL(response.data.next);

          if (loginUrl.pathname.endsWith('/idp/startSSO.ping')) {
            // Ping Federate
            loginUrl.searchParams.append('TargetResource', redirectPath);
          } else if (loginUrl.pathname.endsWith('/adfs/ls/IdpInitiatedSignOn.aspx')) {
            // ADFS
            if (loginUrl.searchParams.get('loginToRp')) {
              const relayState = encodeURIComponent(
                `RPID=${encodeURIComponent(loginUrl.searchParams.get('loginToRp'))}&RelayState=${encodeURIComponent(redirectPath)}`
              );

              loginUrl.searchParams.append('RelayState', relayState);
            }
          } else {
            // default
            loginUrl.searchParams.append('RelayState', redirectPath);
          }

          window.location.href = loginUrl.href;
        } else {
          window.location.href = response.data.next;
        }
      }
    } catch (e) {
      alert(GENERIC_INVALID_CREDENTIALS_MSG);
    }
  }

  get isFromTruGreen() {
    return this.user?.customer_id === 16;
  }

  get isAdmin() {
    return this.user?.role === 'admin';
  }

  get isCoach() {
    return this.user?.role === 'coach';
  }

  get loginCount() {
    return _.get(this.user, 'preferences.loginCount', 1);
  }

  get avatar() {
    return _.get(this.user, 'preferences.avatar');
  }

  get firstName() {
    const firstNameToReturn = _.get(this.user, 'preferences.preferredFirstName', '');

    return firstNameToReturn || _.get(this.user, 'firstName');
  }

  get hasCompletedOnboarding() {
    return _.get(this.user, 'preferences.hasCompletedOnboarding', false);
  }

  async getUsers() {
    const { data } = await Api.getUsers();
    this.users = data;

    return data;
  }

  async getUserAvatars() {
    const { data } = await Api.getUserAvatars();
    this.userAvatars = data;

    return data;
  }

  getUser(userId) {
    return this.users.find((u) => u.id === userId);
  }

  async getTeam() {
    const { data } = await Api.getTeam();
    this.team = data;
  }

  get userTeamsByTags() {
    return _.uniq(this.users.filter((u) => _.get(u, 'preferences.tags', []).find((t) => t.type === 'team')).map((u) => u.preferences.tags.find((t) => t.type === 'team').value)).sort();
  }

  getUserActivity = async (userId) => {
    this.selectedUserActivity = [];

    this.loadingUserActivity = true;
    const userActivityPromises = [
      Api.getAllUserAttempts(userId || this.user?.id),
      Api.getUserActivity(userId || this.user?.id),
    ];

    const [
      { data: conversationAttempts },
      { data },
    ] = await Promise.all(userActivityPromises);

    this.selectedUserConversationActivity = conversationAttempts;
    this.selectedUserActivity = _.orderBy(
      data,
      (d) => new Date(d.date_updated).getTime(),
    ).reverse();

    this.loadingUserActivity = false;

    return data;
  }

  async fetchUserProfile() {
    if (this.user !== null) {
      return null;
    }

    try {
      this.isFetchingUserProfile = true;
      const { status, data } = await Api.getUserProfile();

      this.blockedByPayment = (data?.Customer?.stripeEnabled
        && !data?.Customer?.stripePaid);

      if (status === 401) {
        this.isAuthenticated = false;
        this.ssoUrl = data.ssoUrl;
      } else if (data !== undefined) {
        this.setUserProfile(data);
      }

      this.isFetchingUserProfile = false;
      return data;
    } catch (e) {
      this.isFetchingUserProfile = false;
      this.isAuthenticated = false;
      return null;
    }
  }

  async uploadAvatar(avatarFileData) {
    const { data } = await Api.uploadUserAvatar(avatarFileData);

    return data;
  }

  async updateUser(userData, showNotification = true) {
    const { data } = await Api.updateUser(userData);

    if (data && data.id) {
      this.user = {
        ...this.user,
        ...data,
      };

      if (showNotification) {
        notificationsStore.createNotification('Profile updated successfully');
      }
      return true;
    }

    return false;
  }

  setShowHeader(state = true) {
    this.showingHeader = state;
  }

  async academyLoginByToken() {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    const token = searchParams.get('token');

    searchParams.delete('token');

    const cleanedSearch = searchParams.toString() ? `?${searchParams.toString()}` : '';

    window.history.replaceState({}, document.title, `${window.location.pathname}${cleanedSearch}`);

    if (token) {
      await Api.authenticateToAcademy(token);
    }

    await this.fetchUserProfile();
  }

  async getUsersByTeam(teamId) {
    const { data } = await Api.getUsersByTeam(teamId);
    this.users = data;

    return data;
  }

  async setCustomerInstance(customerId) {
    const { data } = await Api.setCustomerInstance(customerId);

    if (data?.url && data?.customerId) {
      this.selectedInstance = data?.customerId;
      Cookies.set('selectedInstance', data?.customerId);

      await OnboardingStore.fetchWelcomePlacemat();
      await this.fetchUserProfile();

      window.location.href = data?.url;
    }
  }

  async getUsersByTeam(teamId) {
    const { data } = await Api.getUsersByTeam(teamId);
    this.users = data;

    return data;
  }
}

decorate(UserStore, {
  login: action,
  loginRedirect: action,
  users: observable,
  user: observable,
  isFetchingUserProfile: observable,
  isAuthenticated: observable,
  isAdmin: computed,
  isFromTruGreen: computed,
  getUser: action,
  firstName: computed,
  loginCount: computed,
  avatar: computed,
  ssoUrl: observable,
  content: observable,
  contentLoaded: observable,
  fetchUserProfile: action,
  finishOnboarding: action,
  setUserProfile: action,
  getTeam: action,
  selectedUserActivity: observable,
  selectedUserConversationActivity: observable,
  loadingUserActivity: observable,
  showingHeader: observable,
  setShowHeader: action,
  team: observable,
  bannerMode: observable,
  userAvatars: observable,
  userTeamsByTags: computed,
  uploadAvatar: action,
  updateUser: action,
  hasCompletedOnboarding: computed,
  blockedByPayment: observable,
  academyLoginByToken: action,
  setCustomerInstance: action,
  selectedInstance: observable,
  getUsersByTeam: action,
  usersInSelectedTeam: action,
});

export default new UserStore();
